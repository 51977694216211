<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-12">
        <b-card class="card card-custom card-stretch gutter-b" no-body>
            <b-tabs pills card>
                <b-tab active>
                    <template v-slot:title>
                        <i class="fas fa-info-circle mr-3" />
                        Informations client
                    </template>
                    <b-form id="item-form" class="row" @submit.prevent="onSubmitItem">
                        <b-form-group class="col-md-6" label="Prénom :" :disabled="!canEdit">
                            <b-form-input
                                v-model="item.firstname"
                                type="text"
                                placeholder="Prénom"
                                required
                            />
                        </b-form-group>
                        <b-form-group class="col-md-6" label="Nom :" :disabled="!canEdit">
                            <b-form-input
                                v-model="item.lastname"
                                type="text"
                                placeholder="Nom"
                                required
                            />
                        </b-form-group>
                        <b-form-group class="col-md-6" label="Email :" :disabled="!canEdit">
                            <b-form-input
                                v-model="item.email"
                                type="email"
                                placeholder="Email"
                                required
                            />
                        </b-form-group>
                        <b-form-group class="col-md-6" label="Téléphone :" :disabled="!canEdit">
                            <b-form-input
                                v-model="item.phone"
                                type="tel"
                                placeholder="Téléphone"
                                required
                            />
                        </b-form-group>
                        <b-form-group class="col-md-12" label="Adresse :" :disabled="!canEdit">
                            <b-form-textarea
                                v-model="item.address"
                                placeholder="Adresse"
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>
                        </b-form-group>

                        <div v-if="canDelete" class="col-sm-6 mb-2">
                            <b-button @click.prevent="deleteItem" variant="danger">
                                Supprimer le client
                            </b-button>
                        </div>
                        <div v-if="canDelete" class="col-sm-6 text-sm-right">
                            <b-button type="submit" variant="primary">Sauvegarder</b-button>
                        </div>
                    </b-form>
                </b-tab>

                <!-- <b-tab>
                    <template v-slot:title>
                        <i class="fas fa-clipboard mr-3" />
                        Prises en charge
                    </template>
                </b-tab>

                <b-tab>
                    <template v-slot:title>
                        <i class="fas fa-shopping-cart mr-3" />
                        Ventes
                    </template>
                </b-tab> -->
            </b-tabs>
        </b-card>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "customer",
  data() {
    return {
      item: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        address: null,
      }
    };
  },
  computed: {
    ...mapGetters(["hasUserRights"]),
    canEdit() {
        return this.hasUserRights([{ section: 'customers', rights: ['update'] }]);
    },
    canDelete() {
        return this.hasUserRights([{ section: 'customers', rights: ['delete'] }]);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Clients", route: { name: "customers" } }, { title: "Client" }]);
    this.$router.onReady(() => {
        this.fetchItem(this.$route.params.id);
    });
  },
  methods: {
    fetchItem(id) {
        ApiService
            .get('/customers', id)
            .then(({ data }) => {
                this.item = data;
            })
            .catch(() => {
                alert('Client introuvable');
            });
    },
    onSubmitItem() {
        ApiService
            .update('/customers', this.item.id, this.item)
            .then(() => {
                this.$swal('Client mis à jour', '', 'success');
            });
    },
    deleteItem() {
        this.$swal.fire({
            title: 'Êtes-vous sûr ?',
            text: "Souhaitez-vous supprimer ce client ? Cette action est irréversible",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Supprimer',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                ApiService
                    .delete(`/customers/${this.item.id}`)
                    .then(() => {
                        this.$swal.fire('Supprimé !', 'Le client a bien été supprimé', 'success');
                        this.$router.push({ name: 'customers' });
                    });
            }
        })
    },
  },
};
</script>
